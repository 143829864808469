import React from "react";

import { Link } from "gatsby";
import CareersHero from "./careers_hero";
import {
  CalendarIcon,
  LocationMarkerIcon,
  UsersIcon,
  DocumentTextIcon,
  MailIcon,
} from "@heroicons/react/solid";

import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const downloadLinks = {
  1: "https://docs.google.com/document/d/1qCXDdnjWqKA4U87jjQbAnupj7_eSlZrBL_AIbRYLD8c/edit?usp=sharing",
  2: "https://docs.google.com/document/d/1NcOQU2_piqS2pvDbeMYw4Mmnh8GYcH8QZSH95NIpIVs/edit?usp=sharing",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Careers({ data }) {
  // const { loading, error, data } = useQuery(FETCH_JOBS_QUERY);
  console.log("jobs description", data);
  return (
    <>
      {/* {loading && <div class="h-100v w-100v flex justify-center items-center bg-transparent">
  <div
    class="
      animate-spin
      rounded-full
      h-32
      w-32
      border-t-2 border-b-2 border-purple-500
    "
  ></div>
</div>}
      {error && <p>Error: ${error.message}</p>} */}
      {data && (
        <section>
          <CareersHero />

          <div className="  overflow-hidden sm:rounded-md w-2/3 sm:w-full mx-auto mt-8 lg:mt-24">
            <ul role="list" className="divide-y divide-gray-200">
              {data.allJobs.jobs.map((job) => (
                <li key={job.id}>
                  {/* <a href="#" className="block hover:bg-gray-50"> */}
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <p className="font-heading2 text-primary-blue ">
                        {job.role}
                      </p>

                      {/* <div className="ml-2 flex-shrink-0 flex">
                        <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          {job.level}
                        </p>
                      </div> */}
                    </div>
                    <div className="my-8  w-full   sm:flex sm:justify-between items-center">
                      <p className="text-sm w-full md:w-2/3 font-medium text-gray-600 ">
                        {job.short_description}
                      </p>

                      <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                        {/* <p className="flex items-center text-sm text-orange-primary">
                          <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                          pitch@pitchright.ventures
                        </p> */}
                      </div>
                    </div>

                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="sm:flex">
                        {/* <p className="flex items-center text-sm text-gray-500">
                          <UsersIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                          {job.department}
                        </p> */}
                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 ">
                          <LocationMarkerIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          {job.location}
                        </p>
                        {/* <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-4">
                          Remote : {job.remote}
                        </p> */}
                        <Link
                          to={downloadLinks[job.id]}
                          className=""
                          target="_blank"
                          download
                        >
                          <p className="mt-2 flex items-center text-sm  sm:mt-0 sm:ml-4 underline hover:text-orange-400 text-orange-primary">
                            <DocumentTextIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            Full Job Description
                          </p>
                        </Link>
                      </div>
                      <div className="mt-2 flex items-start text-sm text-gray-500 sm:mt-0">
                        {/* <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                        {/* <p>
                          Closing on {job.close_date}
                        </p> */}
                      </div>
                    </div>
                  </div>
                  {/* </a> */}
                </li>
              ))}
            </ul>
          </div>
          <div className="bg-sky-50 h-16"></div>
        </section>
      )}
    </>
  );
}
