import React from 'react';
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Career from '../components/careers';

//export const careerContext = React.createContext()


export default function PeoplePage({ location, data }) {

  return (
    <Layout location={location}>
      {/* <careerContext.Provider value={data} >
        <Career />
      </careerContext.Provider> */}
      <Career data = { data } />  
    </Layout>
  )
}

export const fetchAlljobsQuery = graphql`
{
    allJobs {
        jobs {
        id
        role
        short_description
        close_date
        remote
        level
        company
        location
        department
        }
    }
}
`;